$(function () {
    var ddIdiomas = {
        init: function () {
            $('#country').on('change', function (e) {
                ddIdiomas.process($(this).val());
            });
            ddIdiomas.process($('#country').val());
        },
        process: function(country) {
            ddIdiomas.clear();

            if (country == "10") {
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="English"').prop('selected', true);
            }
            if (country == "12") {
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="English"').prop('selected', true);
            }
            if (country == "1") {
                ddIdiomas.add('Español', 'Español');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Español"').prop('selected', true);
            }
            if (country == "2") {
                ddIdiomas.add('Italiano', 'Italiano');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Italiano"').prop('selected', true);
            }
            if (country == "3") {
                ddIdiomas.add('Française', 'Française');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Française"').prop('selected', true);
            }
            if (country == "4") {
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="English"').prop('selected', true);
            }
            if (country == "5") {
                ddIdiomas.add('Portugues', 'Portugues');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Español"').prop('selected', true);
            }
            if (country == "6") {
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="English"').prop('selected', true);
            }
            if (country == "7") {
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="English"').prop('selected', true);
            }
            if (country == "8") {
                ddIdiomas.add('Español', 'Español');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Español"').prop('selected', true);
            }
            if (country == "9") {
                ddIdiomas.add('Español', 'Español');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Español"').prop('selected', true);
            }
            if (country == "13") {
                ddIdiomas.add('Español', 'Español');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Español"').prop('selected', true);
            }
            if (country == "14") {
                ddIdiomas.add('Español', 'Español');
                ddIdiomas.add('English', 'English');
                $('#idioma option[value="Español"').prop('selected', true);
            }
        },
        clear: function () {
            $('#lang').find('option').remove().end(); 
        },
        add: function (value, text) {
            $('#lang').append(new Option(text, value));
        }
    };
    ddIdiomas.init();
});